<template>
  <div>
    <PageLoader />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="black" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout justify-center wrap>
      <v-flex xs12 md12 px-md-3 px-lg-3 px-xl-3>
        <v-layout wrap>
          <v-flex xs6>
            <span class="homeTop">Add Category</span>
          </v-flex>
          <v-flex xs6 text-right>
            <v-dialog
              :retain-focus="false"
              v-model="dialog"
              persistent
              max-width="800px"
              :key="dialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined v-bind="attrs" v-on="on"> Add </v-btn>
              </template>
              <v-card>
                <v-form v-model="addslider" ref="addcat">
                  <v-card-title>
                    <span class="headline">Add Category</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-layout justify-center wrap>
                        <v-flex xs12>
                          <v-text-field
                            class="homeText"
                            outlined
                            dense
                            v-model="category.order"
                            type="number"
                            required
                            label=" Order of the Category"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            class="homeText"
                            outlined
                            dense
                            v-model="category.name"
                            label=" Name of the Category"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            class="homeText"
                            outlined
                            :rules="rules"
                            dense
                            v-model="category.title"
                            label=" Title of the Category"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 pb-5>
                          <span class="homeText"
                            >Description of the Category</span
                          >
                          <div id="app">
                            <vue-editor
                              v-model="category.description"
                            ></vue-editor>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <v-color-picker
                            dot-size="25"
                            hide-mode-switch
                            mode="hexa"
                            swatches-max-height="200"
                            v-model="category.color"
                          ></v-color-picker>
                        </v-flex>
                        <v-layout justify-start wrap pl-2 pb-5>
                          <v-flex xs12>
                            <img
                              v-if="imagePreview"
                              :src="imagePreview"
                              v-show="showPreview"
                              width="150px"
                              height="150px"
                              class="align-center"
                              contain
                            />
                          </v-flex>
                          <v-flex xs12 sm12 md12 lg12>
                            <v-btn
                              width="180px"
                              block
                              tile
                              depressed
                              :ripple="false"
                              class="text-capitalize"
                              color="#F6F5FF"
                              @click="$refs.showImage.click()"
                            >
                              <v-layout justify-center wrap>
                                <v-flex xs2 sm2 md2 text-left>
                                  <v-icon style="color: #000000"
                                    >mdi-upload</v-icon
                                  >
                                </v-flex>
                                <v-flex xs10 sm10 md10>
                                  <span class="image">UPLOAD IMAGE </span>
                                </v-flex>
                              </v-layout>
                            </v-btn>
                            <input
                              v-show="false"
                              accept="image/*"
                              id="file"
                              ref="showImage"
                              multiple="multiple"
                              type="file"
                              @change="showImage"
                            />
                          </v-flex>
                        </v-layout>
                        <v-layout justify-start wrap pl-2 pb-5>
                          <v-flex xs12>
                            <img
                              v-if="imagePreviewCover"
                              :src="imagePreviewCover"
                              v-show="showPreviewCover"
                              width="150px"
                              height="150px"
                              class="align-center"
                              contain
                            />
                          </v-flex>
                          <v-flex xs12 sm12 md12 lg12>
                            <v-btn
                              width="180px"
                              block
                              tile
                              depressed
                              :ripple="false"
                              class="text-capitalize"
                              color="#F6F5FF"
                              @click="$refs.showImageCover.click()"
                            >
                              <v-layout justify-center wrap>
                                <v-flex xs2 sm2 md2 text-left>
                                  <v-icon style="color: #000000"
                                    >mdi-upload</v-icon
                                  >
                                </v-flex>
                                <v-flex xs10 sm10 md10>
                                  <span class="image">UPLOAD HOME IMAGE </span>
                                </v-flex>
                              </v-layout>
                            </v-btn>
                            <input
                              v-show="false"
                              accept="image/*"
                              id="file"
                              ref="showImageCover"
                              multiple="multiple"
                              type="file"
                              @change="showImageCover"
                            />
                          </v-flex>
                        </v-layout>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">
                      Close
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      :disabled="!addslider"
                      text
                      @click="add()"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-start v-if="list.length > 0">
          <v-flex v-for="(item, i) in list" :key="i" md4 pa-4>
            <v-card style="line-height: 16px">
              <v-img
                style="width: 100%"
                :src="mediaURL + item.image"
                height="400px"
                contain
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#FF2323"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-layout wrap justify-center px-2 py-4>
                <v-flex xs12 text-center>
                  <span class="homeName"> {{ item.name }} </span><br /><br />
                  <span class="homeTitle"> {{ item.title }} </span><br /><br />
                  <span
                    class="showLine"
                    style="
                      font-size: 13px;
                      color: #000000;
                      font-family: poppinsregular;
                    "
                    v-html="item.description"
                  >
                  </span>
                </v-flex>
                <v-flex pt-5 xl6 lg6 md6 pb-2 sm6 xs6 px-5 justify-center>
                  <v-dialog v-model="item.delete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        class="mr-2"
                        outlined
                        block
                        v-on="on"
                        v-bind="attrs"
                        color="red"
                      >
                        Delete
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title
                        >Are you sure you want to delete this Category
                        ?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="item.delete = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItem(item)"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
                <v-flex pt-5 xl6 lg6 md6 pb-2 sm6 xs6 px-5 justify-center>
                  <v-btn
                    small
                    class="mr-2"
                    outlined
                    block
                    color="green"
                    @click="editSlider(item)"
                  >
                    Edit
                  </v-btn>
                </v-flex>
                <br />
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout v-else justify-center wrap pt-5>
          <v-flex xs12 text-center>
            <span class="homeText">No Data Found.....</span>
          </v-flex>
        </v-layout>
        <v-dialog v-model="editdialog" max-width="800px">
          <v-card>
            <v-card-title>
              <span class="headline">Edit Category</span>
            </v-card-title>
            <v-card-text>
              <v-layout justify-center wrap>
                <v-flex xs12>
                  <v-text-field
                    class="homeText"
                    outlined
                    dense
                    v-model="editingitem.order"
                    type="number"
                    required
                    label=" Order of the Category"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md12 lg12>
                  <v-text-field
                    class="homeText"
                    outlined
                    dense
                    v-model="editingitem.name"
                    label=" Name of the Category"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md12 lg12>
                  <v-text-field
                    class="homeText"
                    outlined
                    :rules="rules"
                    dense
                    v-model="editingitem.title"
                    label=" Title of the Category"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 pb-5>
                  <span class="homeText">Description of the Category</span>
                  <div id="app">
                    <vue-editor v-model="editingitem.description"></vue-editor>
                  </div>
                </v-flex>
                <v-flex xs12>
                  <v-color-picker
                    dot-size="25"
                    hide-mode-switch
                    mode="hexa"
                    swatches-max-height="200"
                    v-model="editingitem.color"
                  ></v-color-picker>
                </v-flex>
              </v-layout>
              <v-layout justify-start wrap pl-2 pb-5>
                <v-flex xs12>
                  <img
                    v-if="imagePreview"
                    :src="imagePreview"
                    v-show="showPreview"
                    width="150px"
                    height="150px"
                    class="align-center"
                    contain
                  />
                  <img
                    v-else
                    :src="mediaURL + editingitem.image"
                    width="150px"
                    height="150px"
                    class="align-center"
                    contain
                  />
                </v-flex>
                <v-flex xs12 sm7 md5 lg4>
                  <v-btn
                    width="180px"
                    block
                    tile
                    depressed
                    :ripple="false"
                    class="text-capitalize"
                    color="#F6F5FF"
                    @click="$refs.showImage.click()"
                  >
                    <v-layout justify-center wrap>
                      <v-flex xs2 sm2 md2 text-left>
                        <v-icon style="color: #000000">mdi-upload</v-icon>
                      </v-flex>
                      <v-flex xs10 sm8 md8>
                        <span class="image">UPLOAD IMAGE </span>
                      </v-flex>
                    </v-layout>
                  </v-btn>
                  <input
                    v-show="false"
                    accept="image/*"
                    id="file"
                    ref="showImage"
                    multiple="multiple"
                    type="file"
                    @change="showImage"
                  />
                </v-flex>
              </v-layout>
              <v-layout justify-start wrap pl-2 pb-5>
                <v-flex xs12>
                  <img
                    v-if="imagePreviewCover"
                    :src="imagePreviewCover"
                    v-show="showPreviewCover"
                    width="150px"
                    height="150px"
                    class="align-center"
                    contain
                  />
                  <img
                    v-else
                    :src="mediaURL + editingitem.homeImage"
                    width="150px"
                    height="150px"
                    class="align-center"
                    contain
                  />
                </v-flex>
                <v-flex xs12 sm7 md5 lg4>
                  <v-btn
                    width="180px"
                    block
                    tile
                    depressed
                    :ripple="false"
                    class="text-capitalize"
                    color="#F6F5FF"
                    @click="$refs.showImageCover.click()"
                  >
                    <v-layout justify-center wrap>
                      <v-flex xs2 sm2 md2 text-left>
                        <v-icon style="color: #000000">mdi-upload</v-icon>
                      </v-flex>
                      <v-flex xs10 sm8 md8>
                        <span class="image">UPLOAD HOME IMAGE </span>
                      </v-flex>
                    </v-layout>
                  </v-btn>
                  <input
                    v-show="false"
                    accept="image/*"
                    id="file"
                    ref="showImageCover"
                    multiple="multiple"
                    type="file"
                    @change="showImageCover"
                  />
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="editdialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="edit(editingitem._id)">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import store from "./../../store";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      category: {
        name: null,
        title: null,
        description: null,
        color: null,
        order: null,
      },
      list: [],
      editingitem: [],
      dialog: false,
      rules: [
        (v) => !!v || "Title is required",
        (v) =>
          (v && v.length <= 100) || "Title must be less than 100 characters",
      ],
      addslider: false,
      editdialog: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      imageArray: [],
      imageError: "",
      imageCoverError: "",
      formData: new FormData(),
      selectedFiles: [],
      image: null,
      imagePreview: "",
      showPreview: false,
      file: null,
      imagePreviewCover: "",
      showPreviewCover: false,
      file1: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      selectedImage: null,
      topimageFile: null,
      imageFiles: [],
      id: null,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      store.commit("appLoading", true);
      axios({
        method: "GET",
        url: "/product/category/admingetlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.list = response.data.data;
          store.commit("appLoading", false);
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
    add() {
      this.category["color"] = this.category.color.hexa;
      axios({
        method: "post",
        url: "/product/category/add",
        data: this.category,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            (this.dialog = false), (this.msg = " Added Successully");
            this.showSnackBar = true;
            this.getList();
            Object.keys(this.category).forEach(
              (key) => (this.category[key] = null)
            );
            this.id = response.data.id;
            if (this.imagePreview) {
              this.uploadImage(this.id);
            }
            if (this.imagePreviewCover) {
              this.uploadCoverImage(this.id);
            }
            this.getList();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    showImage(e) {
      this.file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.$refs.showImage.value = null;
        this.imagePreview = e.target.result;
      };
      if (this.file.size > 2145728) {
        this.msg = "File Should be less than 2MB";
        this.showSnackBar = true;
        return;
      }
      this.showPreview = true;
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    showImageCover(e) {
      this.file1 = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.$refs.showImageCover.value = null;
        this.imagePreviewCover = e.target.result;
      };
      if (this.file1.size > 2145728) {
        this.msg = "File Should be less than 2MB";
        this.showSnackBar = true;
        return;
      }
      this.showPreviewCover = true;
      if (this.file1) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file1.name)) {
          reader.readAsDataURL(this.file1);
        }
      }
    },
    uploadImage(item) {
      store.commit("appLoading", true);
      let formData = new FormData();
      formData.append("image", this.file);
      formData.append("id", item);
      axios({
        method: "POST",
        url: "/product/category/image",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          store.commit("appLoading", false);
          if (response.data.status == true) {
            this.msg = "Upload Successfully";
            this.showSnackBar = true;
            this.imagePreview = null;
            this.dialog = false;
            this.editdialog = false;
            this.getList();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.msg = "Can't Upload Cover Image.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },
    uploadCoverImage(item) {
      store.commit("appLoading", true);
      let formData = new FormData();
      formData.append("id", item);
      formData.append("image", this.file1);
      axios({
        method: "POST",
        url: "/product/category/homeimage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          store.commit("appLoading", false);
          if (response.data.status == true) {
            this.msg = "Upload Successfully";
            this.showSnackBar = true;
            (this.imagePreviewCover = null), (this.dialog = false);
            this.editdialog = false;
            this.getList();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.msg = "Can't Upload Cover Image.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },
    edit() {
      this.appLoading = true;
      var user = {};
      user["order"] = this.editingitem.order;
      user["name"] = this.editingitem.name;
      user["title"] = this.editingitem.title;
      user["description"] = this.editingitem.description;
      user["color"] = this.editingitem.color.hexa;
      user["id"] = this.editingitem;
      axios({
        method: "POST",
        url: "/product/category/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: user,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;
            this.getList();
            if (this.imagePreview) {
              this.uploadImage(this.editingitem._id);
            }
            if (this.imagePreviewCover) {
              this.uploadCoverImage(this.editingitem._id);
            }
          } else {
            this.msg = "Can't Update";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/product/category/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getList();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.icon {
  padding-left: 170px;
}
.texttt {
  line-height: 1.5em;
  min-height: 3.5em;
}
.showLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.v-application .primary {
  background-color: red !important;
}
</style>